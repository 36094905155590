const texts = ["Hacemos crecer tu negocio.", "Somos tu aliado digital.", "Optimizamos tus procesos."];
const typingSpeed = 100;
const deletingSpeed = 100;
const delayBetweenTexts = 1000;

let textIndex = 0;
let charIndex = 0;
let isDeleting = false;

const typingElement = document.getElementById("typing-animation");

typingElement.innerText = '';
typingElement.classList.add('blinking');

function typeText() {
  const currentText = texts[textIndex];

  if (isDeleting) {
    typingElement.innerText = currentText.substring(0, charIndex - 1);
    charIndex--;

    if (charIndex === 0) {
      isDeleting = false;
      textIndex = (textIndex + 1) % texts.length;
      typingElement.classList.add('blinking');
      setTimeout(typeText, delayBetweenTexts);
      return;
    }

    setTimeout(typeText, deletingSpeed);
  } else {
    typingElement.innerText = currentText.substring(0, charIndex + 1);
    charIndex++;

    if (charIndex === currentText.length) {
      isDeleting = true;
      typingElement.classList.add('blinking');
      setTimeout(typeText, delayBetweenTexts);
      return;
    }

    setTimeout(typeText, typingSpeed);
  }

  typingElement.classList.remove('blinking');
}

document.addEventListener("DOMContentLoaded", function() {
  setTimeout(typeText, delayBetweenTexts);
});
